import { createBrowserRouter } from "react-router-dom";
import NotFound from "../pages/errors/NotFound";
import InicioSesion from "../pages/InicioSesion";
import TerminarSesion from "../pages/TerminarSesion";
import ComprobacionSesion from "../pages/ComprobacionSesion";
import ProcesarCredenciales from "../pages/ProcesarCredenciales";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <ComprobacionSesion />,
        errorElement: <NotFound />
    },
    {
        path: '/iniciar_sesion',
        element: <InicioSesion />,
        errorElement: <NotFound />
    },
    {
        path: '/procesar_credenciales',
        element: <ProcesarCredenciales />,
        errorElement: <NotFound />
    },
    {
        path: '/terminar_sesion',
        element: <TerminarSesion />,
        errorElement: <NotFound />
    },
    {
        path: '/cerrar_sesion',
        element: <TerminarSesion />,
        errorElement: <NotFound />
    },
])