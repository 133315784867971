import Resumable from "resumablejs"

export class ProcesadorMultimedia {
    constructor() {
        this.URL_DOMINIO = 'https://crud.eksuite.com/api/v2'
        this.URL_OBTENER_TOKEN = `${this.URL_DOMINIO}/procesador/multimedia/monitoreo/token`
        this.URL_MONITORIAR_PROGRESO = `${this.URL_DOMINIO}/procesador/multimedia/monitoreo/conversion`
        this.URL_CONVERTIR_VIDEO = `${this.URL_DOMINIO}/procesador/multimedia/convertir/video`
        this.resp = null
    }

    async generarToken() {
        let response = await fetch(this.URL_OBTENER_TOKEN)
        let data = await response.json()
        return data.token
    }

    async convertirVideo(
        video = null, 
        tokenMonitoreo = null, 
        formato = '', 
        carpeta = '', 
        tabla = '', 
        key = '', 
        callbackCarga = () => {},
        callbackSuccess = () => {},
    ) {
        if(video && tokenMonitoreo) {
            let rsm = new Resumable({
                target: `${this.URL_CONVERTIR_VIDEO}/${formato}/${carpeta}/${tabla}?key=${key}&tokenMonitoreoProgreso=${tokenMonitoreo}`,
                chunkSize: 1 * 1024 * 1024,
                headers: { 'Accept' : 'application/json' },
                testChunks: false,
                throttleProgressCallbacks: 1,
            })

            rsm.on('fileAdded', (file) => {
                console.log('Se agrego archivo...')
                rsm.upload()
            })
            rsm.on('fileProgress', function (file) {
                let progreso = Math.floor(file.progress() * 100)
                this.progresoCargaVideo = progreso
                callbackCarga(progreso)
            })
            rsm.on('fileSuccess', (file, response) => {
                this.resp = JSON.parse(response)
                callbackSuccess(JSON.parse(response))
            })
            rsm.on('fileError', (file, response) => { console.log(response) })

            rsm.addFile(video)
        }
        // else no hay archivo para procesar
    }

    fijaReponse(value) {
        this.resp = value
    }

    dameReponse() {
        return this.resp
    }

    async dameProgresoCoversionVideo(token = '') {
        let response = await fetch(`${this.URL_MONITORIAR_PROGRESO}/${token}`)
        let data = await response.json()
        return data.porcentaje
    }

}
