import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useSelector } from 'react-redux'
import { useMoverJerarquiaMutation, useObtenerSeminariosTemasQuery } from '../../js/service/tema'
import { useEffect, useState } from 'react'

export default function SeminarioTemas({ ShowAlert, tema }) {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** ESTADOS */
    const [seminarios, fijaSeminarios] = useState([])

    /** HOOK */
    const { data, isLoading } = useObtenerSeminariosTemasQuery({ token: sesionSlice.token, temaID: tema.id })
    const [ moverJerarquia ] = useMoverJerarquiaMutation()

    useEffect(() => {
        if(!isLoading) {
            let seminarios = data.map((dato) => {
                return {
                    ...dato.eureka_bs_evento,
                    'index': dato.index
                }
            })
            seminarios = seminarios.sort((seminarioSig, seminarioAct) => {                
                if(seminarioAct.index !== null && seminarioSig.index !== null) return seminarioSig.index - seminarioAct.index
                else {
                    if(seminarioAct?.index !== null) return 1
                    else if(seminarioSig?.index !== null) return -1
                    else return 1
                }
            })
            seminarios = seminarios.map((seminario, index) => {
                if(seminario.index === null) {
                    moverJerarquia({
                        token: sesionSlice.token,
                        seminarioID: seminario.id,
                        index: index,
                        temaID: tema.id
                    })
                }
                // else ya tiene un index guardado

                return {
                    ...seminario,
                    'indexTabla': index
                }
            })
            // console.log(seminarios)
            fijaSeminarios(seminarios)
        }
        // else cargando los seminarios
    }, [isLoading, data])

    if(isLoading) return
    else {
        return (
            <Tabla 
                noData={`No hay seminarios relacionados al tema: ${tema.nombre}`}
                sizeRow="medium"
                checkbox={false}
                busqueda={{ placeholder: 'Buscar...', keys: ['nombre'] }}
                keys={[
                    { text: "Todos", filtro: () => true },
                ]}
                onDragRow={(seminarioRemplazado, indexMov, seminarioMov) => {
                    let seminarioRemp = seminarioRemplazado?.item
                    let seminarioMove = seminarioMov?.item

                    let indiceRemplazo = null
                    if(seminarioRemp.index === null || seminarioRemp.index === seminarioMove.index)
                        indiceRemplazo = seminarioRemp.indexTabla
                    else
                        indiceRemplazo = seminarioRemp.index

                    let indiceMov = null
                    if(seminarioMove.index === null || seminarioRemp.index === seminarioMove.index)
                        indiceMov = seminarioMove.indexTabla
                    else
                        indiceMov = seminarioMove.index

                    let seminariosActualizados = [...seminarios]
                    seminariosActualizados[seminarioRemp.indexTabla] = {
                        ...seminarioMove,
                        index: indiceRemplazo,
                        indexTabla: indiceRemplazo
                    }
                    seminariosActualizados[seminarioMove.indexTabla] = {
                        ...seminarioRemp,
                        index: indiceMov,
                        indexTabla: indiceMov
                    }

                    moverJerarquia({
                        token: sesionSlice.token,
                        seminarioID: seminarioRemp.id,
                        index: indiceMov,
                        temaID: tema.id
                    })
                    moverJerarquia({
                        token: sesionSlice.token,
                        seminarioID: seminarioMove.id,
                        index: indiceRemplazo,
                        temaID: tema.id
                    })
                    fijaSeminarios(seminariosActualizados)
                }}
                headers={[
                    { text: "Nombre", key: "nombre", type: "text" },
                    { text: "Fecha", key: "fecha_inicio", type: "date" },
                    { text: "Estatus", key: "estatus", type: "text" },
                ]}
                data={
                    buildData(seminarios, [
                        { key: 'nombre', type: 'text' },
                        { key: 'fecha_inicio', type: 'date' },
                        {
                            key: 'estatus', type: 'text', text: (item) => {
                                if (item.estatus === "activo") {
                                    return <ChipEstatus texto={"Activo"} tipo="Aceptado"></ChipEstatus>
                                }
                                if (item.estatus === "archivado") {
                                    return <ChipEstatus texto={"Archivado"} tipo="Error"></ChipEstatus>
                                }
                            }
                        },
                    ])
                }
            />
        )
    }
}