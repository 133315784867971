import { Formulario, Input, ImageInput, Select, Tabla, buildData, ChipEstatus } from "eureka-design"
import { useEffect, useState } from "react"
import { 
    useCrearBeneficioMutation, 
    useCrearMembresiaMutation, 
    useCrearSubcripcionMutation, 
    useGuardarFotoMutation, 
    useObtenerMembresiaQuery, 
    useObtenerTemasPermitidosMutation
} from "../../js/service/membresia"
import noImage from "../../img/nologo.png"
import { useSelector } from "react-redux"
import { CICLOS_MEMBRESIA, adaptarDatosSelect, obtenerFoto } from "../../js/util"

export const FORM_MEMBRESIA = 'form_membresia'
const INACTIVO = 'inactivo'
const ACTIVO = 'activo'

export default function FormMembresia({ ShowAlert }) {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)
    const formMembresia = useSelector(state => state.handlerQuerySlice[FORM_MEMBRESIA])

    /** ESTADOS */
    const [membresiaID, fijaMembresiaID] = useState(formMembresia.id ?? null)
    const [subcripcionID, fijaSubcripcionID] = useState(null)
    const [beneficioID, fijaBeneficioID] = useState(null)
    const [fotoID, fijaFotoID] = useState(null)
    const [subcripciones, fijaSubcripciones] = useState([])

    /** HOOK */
    const { data: membresia, isLoading: cargandoMembresia } = useObtenerMembresiaQuery({ 
        token: sesionSlice.token, 
        id: formMembresia.id 
    })
    const [
        crearMembresia,
        {
            isLoading: actualizadoMembresia,
            isError: membresiaErronea,
            isSuccess: membresiaActualizada,
        }
    ] = useCrearMembresiaMutation()
    const [
        crearSubscripcion,
        {
            isLoading: actualizadoSubscripcion,
            isError: subscripcionErronea,
            isSuccess: subscripcionActualizada,
        }
    ] = useCrearSubcripcionMutation()
    const [
        crearBeneficio,
        {
            isLoading: actualizadoBeneficio,
            isError: beneficioErroneo,
            isSuccess: beneficioActualizado,
        }
    ] = useCrearBeneficioMutation()
    const [guardarFoto] = useGuardarFotoMutation()
    const [obtenerTemasPermitidos] = useObtenerTemasPermitidosMutation()

    useEffect(() => {
        if(!cargandoMembresia) {
            // console.log(membresia)
            let subcripciones = [
                { id: null, recurrencia: 'Mensual', precio: 0, estatus: INACTIVO },
                { id: null, recurrencia: 'Anual', precio: 0, estatus: INACTIVO }
            ]

            fijaSubcripcionID(membresia?.eureka_pa_subscripciones[0]?.id ?? null)
            fijaFotoID(membresia?.eureka_cr_multimedia?.id ?? null)
            fijaBeneficioID(membresia?.eureka_me_membresias_beneficios[0]?.id ?? null)

            if(membresia?.eureka_pa_subscripciones?.length > 0) {
                subcripciones = subcripciones.map((sub) => {
                    let indice = membresia.eureka_pa_subscripciones.findIndex((subMeb) => subMeb.recurrencia === sub.recurrencia)
                    if(indice !== -1) {
                        return membresia.eureka_pa_subscripciones[indice]
                    }
                    else {
                        return sub
                    }
                })
                fijaSubcripciones(subcripciones)
            }
            else {
                fijaSubcripciones(subcripciones)
            }
        }
        // else esperar a obtener los datos de la membresia
    }, [
        fijaSubcripcionID,
        fijaFotoID,
        fijaBeneficioID,
        membresia,
        cargandoMembresia,
    ])

    const obtenerMembresiaID = async () => {
        if(membresiaID) return membresiaID
        else return await handlerMembresia('nombre', '')
    }

    const handlerMembresia = async (nom, val) => {
        return await crearMembresia({
            token: sesionSlice.token,
            id: membresiaID, 
            body: { [nom]: val }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                fijaMembresiaID(res.mensaje.id)
                return res.mensaje.id
            }
            else return membresiaID
        })
    }

    const handlerSubscripcion = async (nom, val, subID = null, membID = null) => {
        return await crearSubscripcion({
            token: sesionSlice.token,
            id: subID, 
            body: { [nom]: val, 'membresia': membID }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                // fijaSubcripcionID(res.mensaje.id)
                return res.mensaje.id
            }
            else return subID
        })
    }

    const handlerBeneficios = async (nom, val) => {
        let id = await obtenerMembresiaID()
        obtenerTemasPermitidos({
            token: sesionSlice.token,
            membresiaExcepcionID: id
        })
        .unwrap()
        .then((beneficios) => {
            beneficios = beneficios.filter((beneficio) => {
                return beneficio.incremento === parseInt(val)
            })

            if(beneficios.length > 0) {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Temas no permitidos',
                    mensaje: 'Otra membresia ya cuenta con los temas permitidos especificado, ingrese de nuevo.'
                })
            }
            else {
                crearBeneficio({
                    token: sesionSlice.token,
                    id: beneficioID, 
                    body: { 
                        [nom]: val, 'membresia': id, 'beneficio': 'Temas permitidos' 
                    }
                })
                .unwrap()
                .then((res) => {
                    if(res?.mensaje?.id) {
                        fijaBeneficioID(res.mensaje.id)
                        return res.mensaje.id
                    }
                    else return beneficioID
                })
            }
        })
    }

    const handlerFoto = (e) => {
        guardarFoto({
            token: sesionSlice.token,
            id: fotoID,
            configFile: {
                file: e.target.files[0],
                carpeta: 'imagenes',
                nombre: 'nombre',
            },
        })
        .unwrap()
        .then((response) => {
            if(response?.mensaje?.id) {
                handlerMembresia('logo', response.mensaje.id)
            }
            // else el registro se actualizo
        })
    }

    const handlerInput = (val, nom) => {
        handlerMembresia(nom, val)
    }

    const handlerInputBeneficio = (val, nom) => {
        if(val && val !== '') {
            handlerBeneficios(nom, val)
        }
        // else input vacío
    }

    const handlerInputSubcripcion = (val, nom) => {
        if(val && val !== '') {
            if(nom === 'recurrencia') {
                val = val.value
            }
            // else no es un input select
            handlerSubscripcion(nom, val)
        }
        // else input vacío
    }

    if(cargandoMembresia) {
        return
        // return <p>Cargando por favor espere...</p>
    }
    else {
        return (
            <>
                <Formulario
                    titulo={`Editar Membresia ${membresia?.nombre && ''}`}
                    isLoading={[actualizadoMembresia, actualizadoSubscripcion, actualizadoBeneficio]}
                    isError={[membresiaErronea, subscripcionErronea, beneficioErroneo]}
                    isSuccess={[membresiaActualizada, subscripcionActualizada, beneficioActualizado]}
                >
                    <ImageInput 
                        id={'foto'}
                        preview={obtenerFoto(membresia, noImage)}
                        avatar={true}
                        changeFunction={handlerFoto}
                        isLoading={actualizadoMembresia}
                    />
                    <Input
                        nombre="nombre"
                        placeholder="Nombre"
                        iconLeft="fa-solid fa-file-signature"
                        value={membresia?.nombre}
                        changeFunction={handlerInput}
                        isLoading={actualizadoMembresia}
                    />
                    <Input
                        type="textarea"
                        nombre="descripcion"
                        placeholder="Descripción"
                        iconLeft="fa-solid fa-file-alt"
                        value={membresia?.descripcion}
                        changeFunction={handlerInput}
                        isLoading={actualizadoMembresia}
                    />
                    {/* <Input
                        type="number"
                        nombre="precio"
                        placeholder="Precio"
                        iconLeft="fa-solid fa-dollar"
                        value={membresia?.eureka_pa_subscripciones[0]?.precio}
                        changeFunction={handlerInputSubcripcion}
                        isLoading={actualizadoSubscripcion}
                        max="9999"
                    />
                    <Select
                        nombre="recurrencia"
                        iconLeft="fa-solid fa-circle-dollar-to-slot"
                        placeholder="Ciclo"
                        options={adaptarDatosSelect(CICLOS_MEMBRESIA, 'tipo', 'tipo')}
                        value={membresia?.eureka_pa_subscripciones[0]?.recurrencia}
                        changeFunction={handlerInputSubcripcion}
                        isLoading={actualizadoSubscripcion}
                    >
                    </Select> */}

                    <h3>Subcripciones</h3>
                    <Tabla
                        noData="Sin subcripciones"
                        checkbox={false}
                        rowSize="medium"
                        keys={[
                            { text: "Todos", filtro: () => true },
                        ]}
                        headers={[
                            { text: 'Tipo', key: 'recurrencia', type: 'text' },
                            { text: 'Precio', key: 'precio', type: 'precio' },
                            { text: 'Estatus', key: 'precio', type: 'precio' },
                        ]}
                        data={
                            buildData(subcripciones, [
                                { 
                                    key: 'recurrencia', 
                                    type: 'text',
                                },
                                { 
                                    key: 'precio', 
                                    type: 'precio',
                                    input: {
                                        type: 'number',
                                        nombre: 'precio',
                                        max: '9999',
                                        onEnter: async function(value, nombre, row) {
                                            let subcripcion = row.item
                                            let subcripcionesNuevas = subcripciones.filter((sub) => sub.recurrencia !== subcripcion.recurrencia)
                                            let membID = null

                                            if(subcripcion.id === null) {
                                                membID = await obtenerMembresiaID()
                                                subcripcion['id'] = await handlerSubscripcion('recurrencia', subcripcion.recurrencia, null, membID)
                                                subcripcion['estatus'] = ACTIVO
                                            }
                                            else {
                                                membID = membresiaID
                                            }

                                            handlerSubscripcion(nombre, parseFloat(value), subcripcion.id, membID)

                                            subcripcionesNuevas.push({
                                                ...subcripcion,
                                                'precio': parseFloat(value)
                                            })
                                            fijaSubcripciones(subcripcionesNuevas)
                                        }
                                    }
                                },
                                {
                                    key: 'estatus', 
                                    type: 'text', 
                                    text: (item) => {
                                        let estatus = null
                                        let tipo = null
                                        if(item.id) {
                                            estatus = ACTIVO
                                            tipo = 'Aceptado'
                                        }
                                        else {
                                            estatus = INACTIVO
                                            tipo = 'Error'
                                        }
                                        return <ChipEstatus texto={estatus} tipo={tipo}></ChipEstatus>
                                    }
                                }
                            ])
                        }
                    />

                    <h3>Beneficios</h3>
                    <Input
                        type="number"
                        nombre="incremento"
                        placeholder="Temas Permitidos"
                        iconLeft="fa-solid fa-video"
                        max="100"
                        value={membresia?.eureka_me_membresias_beneficios[0]?.incremento}
                        changeFunction={handlerInputBeneficio}
                        isLoading={actualizadoBeneficio}
                    />
                </Formulario>
            </>
        )
    }
}