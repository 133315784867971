import { Folio, FolioInfo, FolioSection, VideoPlayer, ChipEstatus, Chip } from "eureka-design";
import { useSelector } from "react-redux";
import { useObtenerSeminarioQuery } from "../../js/service/seminario";
import { MESES, capitalize, obtenerArchivo } from "../../js/util";
import { config } from "../../config/config";

const FORM_SEMINARIO = 'form_seminario'

export default function Seminario() {
    /** ESTADOS GLOBALES */
    const formSeminario = useSelector(state => state.handlerQuerySlice[FORM_SEMINARIO])
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const { data: seminario, isLoading: cargandoSeminario } = useObtenerSeminarioQuery({
        token: sesionSlice.token,
        id: formSeminario.id,
    }) 

    if(cargandoSeminario) {
        return
        // return <p>Cargando información, por favor espere...</p>
    }
    else {
        let fecha = new Date(seminario.fecha_inicio)
        let multimedia = seminario.eureka_cr_multimedia
        let temas = seminario.eureka_bs_eventos_intereses

        return (
            <Folio titulo="">
                <div style={{ width: "100%", display: "flex", }}>
                    <VideoPlayer
                        src={`${config.crufdekFileURL}/${multimedia?.carpeta}/${multimedia?.nombre}/video`}
                    ></VideoPlayer>

                    <FolioInfo titulo={seminario.nombre}>
                        <FolioSection titulo={"Fecha de creación"} >
                            <span>{`${fecha.getDate()} de ${MESES[fecha.getMonth()]} del ${fecha.getFullYear()}`}</span>
                        </FolioSection>
                        <FolioSection titulo={"Descripción"} >
                            <span>{seminario.descripcion}</span>
                        </FolioSection>
                        <FolioSection titulo={"Estatus actual"} >
                            <ChipEstatus 
                                texto={capitalize(seminario.estatus)} 
                                tipo={seminario.estatus === 'activo' ? "Aceptado" : "Error"}
                            ></ChipEstatus>
                        </FolioSection>
                        <FolioSection titulo={temas.length > 0 ? "Temas" : ""}></FolioSection>
                        <div style={{display: 'flex', gap: '5px'}}>
                            {
                                temas.map((tema, index) => {
                                    return <Chip key={index} texto={tema.eureka_in_interese.nombre}/>
                                })
                            }
                        </div>
                    </FolioInfo>
                </div>
            </Folio>
        )
    }
}