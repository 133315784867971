import { Perfil, PerfilInfo, PerfilSection } from "eureka-design"
import { MESES, obtenerFoto, obtenerMembresia } from "../../js/util"
import { useSelector } from "react-redux"
import { useObtenerUsuarioQuery } from "../../js/service/usuario"

export default function Usuario({ usuarioID }) {
    const sesionSlice = useSelector(state => state.sesionSlice)
    const { data: usuario, isLoading: cargandoUsuario } = useObtenerUsuarioQuery({ token: sesionSlice.token, columna: 'id', valor: usuarioID })

    if(cargandoUsuario) {
        return
        // return <p>Cargando información del usuario, por favor espere...</p>
    }
    else {
        let fecha = new Date(usuario.fecha_registro)
        if(fecha) {
            fecha = `${fecha.getDate()} de ${MESES[fecha.getMonth()]} del ${fecha.getFullYear()}`
        }
        else {
            fecha = 'Sin fecha de registro'
        }

        return (
            <Perfil
                foto={obtenerFoto(usuario)}
                nombre={usuario.nombre}
                correo={usuario.correo}
                telefono={usuario.telefono}

            >
                <PerfilInfo titulo="Avanzado">
                    <PerfilSection titulo="Membresía" text={obtenerMembresia(usuario)}></PerfilSection>
                    <PerfilSection titulo="Seminarios vistos" text="1,500 (Es una prueba)"></PerfilSection>
                    <PerfilSection titulo="Fecha de ingreso" text={fecha}></PerfilSection>
                </PerfilInfo>
            </Perfil>
        )
    }
}