import { Tabla, buildData, ChipEstatus, ChipContact } from "eureka-design"
import { useGetCuentaFiscalesQuery } from "../../js/service/fiscal"
import { useState, useEffect } from "react"

export default function CuentasFiscales({ UpProps, Push, usuario, busqueda }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetCuentaFiscalesQuery({ search: search, usuario: usuario.id })


    const BotonesRow = () => {
        const botones = [];

        botones.push([
            {
                icono: "fa-solid fa-file-edit",
                tooltip: "Editar",
                onClick: (item) => {
                    item = item.item;
                    Push('form_cuenta_fiscal', { cuenta: item })
                },
            }
        ])


        return botones;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar cuenta",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        checkbox={false}

        keys={[
            {
                text: "Todos",
                detalle: data && data.length > 0 ? data.length : 0,
                filtro: () => true
            },

        ]}
        botonesHead={[
            {
                icono: "fa-solid fa-plus",
                tooltip: "Agregar cuenta",
                onClick: (item) => {
                    Push('form_cuenta_fiscal')
                }
            }
        ]}
        headers={[
            { text: "Representante", key: "nombre", type: "text" },
            { text: "Num. cuenta", key: "numero_cuenta", type: "text" },
            { text: "RFC", key: "rfc", type: "text" },
            { text: "", key: "estatus", type: "text" }
        ]}
        botonesRow={BotonesRow()}
        data={buildData(data ?? [], [
            { key: "nombre", type: "text" },
            {
                key: "numero_cuenta", type: "text"
            },
            {
                key: "rfc", type: "text"
            },
            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "activo") {
                        chip = <ChipEstatus texto={"Aprobada"} tipo="Aceptado" />
                    }

                    if (item.estatus === "solicitud") {
                        chip = <ChipEstatus texto={"Solicitud"} tipo="Pendiente" />
                    }


                    return chip;
                }
            }

        ])}
    >

    </Tabla >
}
