import { Tabla, buildData, Miniatura } from "eureka-design"
import { useDispatch, useSelector } from "react-redux"
import { useObtenerMembresiasQuery } from "../../js/service/membresia"
import { obtenerFoto } from "../../js/util"
import noImage from "../../img/nologo.png"
import { FORM_MEMBRESIA } from "./FormMembresia"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { CARD_MEMBRESIA } from "./Membresia"

export default function Membresias({ Push }) {
    const dispatch = useDispatch()
    
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const { data: membresias, isLoading: cargandoMembresias } = useObtenerMembresiasQuery({token: sesionSlice.token})

    if(cargandoMembresias) {
        return
        // return <p>Cargando membresías, por favor espere...</p>
    }
    else {
        // console.log(membresias)
        
        return (
            <Tabla
                checkbox={false}
                noData="Aún no hay membresías"
                rowSize="medium"
                keys={[
                    { text: "Todos", filtro: () => true },
                ]}
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                headers={[
                    { text: "", key: "foto", type: "text", size: "small" },
                    { text: "Nombre", key: "nombre", type: "text" },
                    { text: "Temas Permitidos", key: "temas", type: "number" },
                    { text: "Total Usuarios", key: "total", type: "text" },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Crear',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_MEMBRESIA,
                                content: { id: null }
                            }))
                            Push('form_membresias')
                        }
                    }
                ]}
                onClickRow={(item) => {
                    dispatch(setHandler({
                        state: CARD_MEMBRESIA,
                        content: { id: item.id }
                    }))
                    Push('membresia')
                }}
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_MEMBRESIA,
                                content: { id: item.id }
                            }))
                            Push('form_membresias')
                        }
                    }
                ]}
                data={
                    buildData(membresias, [
                        {
                            key: 'foto', type: 'text', size: 'small', text: (item) => {
                                return (
                                    <Miniatura
                                        size="medium"
                                        src={obtenerFoto(item, noImage)}
                                    ></Miniatura>
                                )
                            }
                        },
                        { key: 'nombre', type: 'text' },
                        {
                            key: 'temas', 
                            type: 'number',
                            value: (item) => {
                                let temasPermitidos = item?.eureka_me_membresias_beneficios[0]?.incremento
                                return temasPermitidos
                            },
                        },
                        {
                            key: 'total', 
                            type: 'total',
                            value: (item) => {
                                let subcripcion = item.eureka_pa_subscripciones[0]
                                return subcripcion.eureka_pa_subscriptores.length
                            },
                            text: (item) => {
                                let subcripcion = item.eureka_pa_subscripciones[0]
                                return subcripcion.eureka_pa_subscriptores.length
                            },
                        }
                    ])
                }
            >
            </Tabla>
        )
    }
}