import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_SEMINARIOS = 'EurekaBsEvento'
const TABLA_MULTIMEDIA_SEMINARIO = 'EurekaBsEventosMultimedia'
const TABLA_TEMAS = 'EurekaInInterese'
const TABLA_TEMA_SEMINARIO = 'EurekaBsEventosInterese'
const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'

export const RELACION_EVENTO_MULTIMEDIA = 'eureka_bs_eventos_multimedia'

export const seminarioApi = createApi({
    reducerPath: 'seminarioApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Seminario'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerSeminarios: builder.query({
            /**
             * @param { token: string, tipoSeminario: string } data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_SEMINARIOS)
                if(data.tipoSeminario){
                    body = body.where('tipo', '=', data.tipoSeminario)
                }
                // else no buscar por tipo

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                            .with('eureka_bs_eventos_intereses.eureka_in_interese')
                            .with('eureka_bs_eventos_multimedia.eureka_cr_multimedia')
                            .with('eureka_cr_multimedia')
                            .get()
                }
            },
        }),
        obtenerTemas: builder.query({
            /**
             * @param { token: string } data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_TEMAS).get()
                }
            },
        }),
        obtenerSeminario: builder.query({
            /**
             * @param { token: string, id: number } data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_SEMINARIOS)
                            .with('eureka_bs_eventos_intereses.eureka_in_interese')
                            .with('eureka_bs_eventos_multimedia.eureka_cr_multimedia')
                            .with('eureka_cr_multimedia')
                            .where('id', '=', data.id)
                            .first()
                }
            },
        }),
        crearSeminario: builder.mutation({
            /**
             * @param { toke: string, id: number } data
             */
            query: (data) => {
                let body = new EkQuery(TABLA_SEMINARIOS)
                if(data.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)   
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        guardarVideoAudio: builder.mutation({
            /**
             * @param { token: string, id: number, configFile: object }
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if(data.id) body = body.where('id', data.id)
                // else se creara el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${data.token}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        guardarPDF: builder.mutation({
            /**
             * @param { toke: string, id: number, configFile: object } data
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${data.token}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        relacionarMultimedia: builder.mutation({
            /**
             * @param { toke: string, id: number, body: object } data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA_SEMINARIO)
                if(data.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        relacionarTema: builder.mutation({
            /**
             * @param { toke: string, id: number, body: object } data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_TEMA_SEMINARIO)
                if(data.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})

export const {
    useObtenerSeminariosQuery,
    useObtenerTemasQuery,
    useObtenerSeminarioQuery,
    useCrearSeminarioMutation,
    useGuardarVideoAudioMutation,
    useGuardarPDFMutation,
    useRelacionarMultimediaMutation,
    useRelacionarTemaMutation,
} = seminarioApi