import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_ROL = "EurekaCrRol"
const TABLA_RELACION_ROL = "EurekaCrRolUsuario"

export const fiscalApi = createApi({
    reducerPath: 'fiscalApi',
    baseQuery: fetchBaseQuery({ baseUrl: "https://payment.eksuite.com" }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        generarCuentaFiscal: builder.mutation({
            query: (data) => {
                return {
                    url: `/api/v1/stripe/crear/proveedor?key=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`,
                    method: 'POST',
                    enctype: "multipart/form-data",
                    headers: {
                        'Authorization': `Bearer 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`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: data
                }
            },
        }),

    })
})

export const cuentaFiscalApi = createApi({
    reducerPath: 'cuentaFiscalApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    refetchOnMountOrArgChange: true,
    tagTypes: ['Cuentas'],
    endpoints: (builder) => ({
        getCuentaFiscales: builder.query({
            query: ({ usuario }) => {

                let body = [];
                body = new EkQuery("EurekaPaCuentaFiscal")
                    .where("usuario", usuario)
                    .get();
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ['Cuentas'],
        }),
        crearCuentaFiscal: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaPaCuentaFiscal")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaPaCuentaFiscal")
                        .where("id", data.id)
                        .update(data.body);
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    enctype: "multipart/form-data",
                    body: body
                }
            },
            invalidatesTags: ['Cuentas'],
        }),
    })
})

export const {
    useGenerarCuentaFiscalMutation,
} = fiscalApi

export const {
    useCrearCuentaFiscalMutation,
    useGetCuentaFiscalesQuery
} = cuentaFiscalApi