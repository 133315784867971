import { MiPerfil } from "eureka-design"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { capitalize, obtenerFoto, obtenerRol } from "../../js/util"
import { FORM_USUARIO } from "../usuarios/FormUsuario"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useObtenerUsuarioQuery } from "../../js/service/usuario"
import { useObtenerTemasQuery } from "../../js/service/seminario"

export default function Perfil({ Push }) {
    /** ESTADOS */
    const navegacion = useNavigate()
    const dispatch = useDispatch()

    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const { data: usuario, isLoading: cargandoUsuario, error: errorUsuario } = useObtenerUsuarioQuery({
        token: sesionSlice.token,
        id: sesionSlice.user.id
    })


    if (errorUsuario) {
        return <p>{errorUsuario}</p>
    }
    else if (cargandoUsuario) {
        return
        // return <p>Cargando tu información, por favor espere...</p>
    }
    else {
        return (
            <MiPerfil
                avatar={obtenerFoto(usuario)}
                nombre={usuario.nombre}
                correo={usuario.correo}
                telefono={usuario.telefono ?? 'Sin teléfono'}
                extrasInfo={[
                    { titulo: 'Rol', contenido: capitalize(obtenerRol(usuario)) },
                ]}
                botones={[
                    {
                        texto: 'Editar perfil',
                        iconLeft: 'fas fa-user-edit',
                        onClick: () => {
                            dispatch(setHandler({
                                state: FORM_USUARIO,
                                content: { id: usuario.id }
                            }))
                            Push('editar_perfil', { esEdicionPropia: true })
                        }
                    },
                    {
                        texto: 'Mis cuentas fiscales',
                        iconLeft: 'fas fa-file-invoice-dollar',
                        onClick: () => {

                            Push('cuentas_fiscales')
                        }
                    },
                    {
                        texto: 'Cerrar sesión',
                        iconLeft: 'fas fa-arrow-right-from-bracket',
                        onClick: () => { navegacion('/terminar_sesion') }
                    }
                ]}
            >
            </MiPerfil>
        )
    }
}