import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_MEMBRESIA = 'EurekaMeMembresia'
const TABLA_SUBSCRIPCION = 'EurekaPaSubscripcione'
const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'
const TABLA_SUBSCRIPTOR = 'EurekaPaSubscriptore'
const TABLA_BENEFICIOS = 'EurekaMeMembresiasBeneficio'

export const membresiaApi = createApi({
    reducerPath: 'membresiaApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Membresia'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerMembresias: builder.query({
            /**
             * 
             * @param {{ token: string }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MEMBRESIA)
                                .with('eureka_me_membresias_beneficios')
                                .with('eureka_cr_multimedia')
                                .with('eureka_pa_subscripciones.eureka_pa_subscriptores')
                                // .where('estatus', '=', 'Activo')
                                .get()
                }
            },
        }),
        obtenerMembresia: builder.query({
            /**
             * 
             * @param {{ token: string, id: int }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MEMBRESIA)
                                .with('eureka_me_membresias_beneficios')
                                .with('eureka_cr_multimedia')
                                .with('eureka_pa_subscripciones.eureka_pa_subscriptores')
                                .where('id', '=', data.id)
                                // .where('estatus', '=', 'Activo')
                                .first()
                }
            },
        }),
        crearMembresia: builder.mutation({
            /**
             * 
             * @param {{ token: string, body: object, id: int }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MEMBRESIA)
                if(data.id) body = body.where('id', data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearSubcripcion: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_SUBSCRIPCION)
                if(data.id) body = body.where('id', data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearBeneficio: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_BENEFICIOS)
                if(data.id) body = body.where('id', data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerTemasPermitidos: builder.mutation({
            /**
             * 
             * @param {{ token: string, membresiaExcepcionID: int }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_BENEFICIOS)
                            .where('membresia', '!=', data.membresiaExcepcionID)
                            .get()
                }
            },
            transformResponse: (data) => {
                return data
            },
        }),
        guardarFoto: builder.mutation({
            /**
             * @param {{ toke: string, id: number, configFile: object }} data
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) {
                    body = body.where('id', data.id)
                }
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${data.token}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        crearSubcriptor: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_SUBSCRIPTOR)
                if(data.id) body = body.where('id', data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    })
})

export const {
    useObtenerMembresiasQuery,
    useCrearMembresiaMutation,
    useObtenerMembresiaQuery,
    useCrearSubcripcionMutation,
    useGuardarFotoMutation,
    useCrearSubcriptorMutation,
    useCrearBeneficioMutation,
    useObtenerTemasPermitidosMutation,
} = membresiaApi