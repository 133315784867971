import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_TEMA_SEMINARIO = 'EurekaBsEventosInterese'

export const temaApi = createApi({
    reducerPath: 'temaApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Tema'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerSeminariosTemas: builder.query({
            /**
             * @param {{ token: string, temaID: int }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_TEMA_SEMINARIO)
                if(data.temaID){
                    body = body.where('interes', '=', data.temaID)
                }
                // else no buscar por tema

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                            .with('eureka_bs_evento')
                            .with('eureka_bs_evento.eureka_bs_eventos_multimedia.eureka_cr_multimedia')
                            .with('eureka_bs_evento.eureka_cr_multimedia')
                            .get()
                }
            },
        }),
        moverJerarquia: builder.mutation({
            /**
             * 
             * @param {{ token: string, seminarioID: int, index: int, temaID: int }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_TEMA_SEMINARIO)
                                .where('eventos', data.seminarioID)
                                .where('interes', data.temaID)
                                .update({ 'index': data.index })
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    }),
})

export const {
    useObtenerSeminariosTemasQuery,
    useMoverJerarquiaMutation,
} = temaApi