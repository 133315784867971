import {
    DIA, 
    crearCookie,
    obtenerCookie, 
    configurarContadorInactividad,
    refrescarToken,
    verificarSesion
} from "account-react-eurekasigma"
import { useDispatch, useSelector } from "react-redux"
import { fijaToken, fijaUsuario } from "../js/feactures/SesionSlice"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { config } from "../config/config"
import Index from "./layout/Index"
import { useObtenerRolesQuery } from "../js/service/rol"
import { CargandoPagina } from "eureka-design"

const UN_MIN = 60000
const TIEMPO_REFRESCAR_SESION = 50
const TIEMPO_INACTIVIDAD = 60 * 24 * 15
let token = null

const ComprobacionSesion = () => {
    /** ESTADOS */
    const [rolesSistema] = useState(config.roles)
    const [verificandoSesion, fijaVerificacion] = useState(true)
    const navegacion = useNavigate()
    const dispatch = useDispatch()

    /** OBTENER ESTADOS GLOBALES  */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** BASE DE DATOS */
    const { data: roles, isLoading: cargandoRoles } = useObtenerRolesQuery({ token: sesionSlice.token })

    useEffect(() => {
        const iniciarVariablesSesion = (user = null, token = '') => {
            dispatch(fijaUsuario(user))
            dispatch(fijaToken(token))
            crearCookie(config.nomCookieUsuario, JSON.stringify(user), true, { tiempo: 15, tipo: DIA })
            crearCookie(config.nomCookieToken, token, true, { tiempo: 15, tipo: DIA })
            fijaVerificacion(false)
        }

        const verificarRol = (user = null, token = '') => {
            fijaVerificacion(false)
        }
        
        /** COMPROBAR SESIÓN */
        if(verificandoSesion && !cargandoRoles) {
            if(sesionSlice.user === null) {
                let user = JSON.parse(obtenerCookie(config.nomCookieUsuario, true))
                let token = obtenerCookie(config.nomCookieToken, true)

                verificarSesion(token).then((data)  => {
                    if(user === null || token === '') navegacion('/terminar_sesion')
                    else {
                        if(data === undefined && token !== '') {
                            refrescarToken(token).then(nuevoToken => {
                                iniciarVariablesSesion(user, nuevoToken)
                            })
                        }
                        else iniciarVariablesSesion(user, token)
                    }
                })
            }
            else {
                verificarRol(sesionSlice.user, sesionSlice.token)
            }

            if(sesionSlice.usuario !== null && sesionSlice.token !== null) {
                token = sesionSlice.token
                configurarContadorInactividad(TIEMPO_INACTIVIDAD, `${window.location.origin}/terminar_sesion`)
                
                setInterval(() => {
                    refrescarToken(token).then(nuevoToken => {
                        token = nuevoToken
                        if(nuevoToken !== undefined) {
                            iniciarVariablesSesion(sesionSlice.user, nuevoToken)
                        }
                        // else el token no se creo por un posible error
                    })
                }, (TIEMPO_REFRESCAR_SESION * UN_MIN))
            }
        }
        // else esperar a que carguen los roles
    }, [navegacion, dispatch, sesionSlice, verificandoSesion, fijaVerificacion, cargandoRoles])

    if(verificandoSesion) 
        return (
            <CargandoPagina
                mensaje="Cargando, por favor espere..."
                colorPrincipal="#816518"
            ></CargandoPagina>
        )
    else
        return <Index />
}

export default ComprobacionSesion