import { config } from "../config/config";
import { AccountEurekaSigma } from "account-react-eurekasigma";

const InicioSesion = () => {
    let parametrosURL = new URLSearchParams(window.location.search)
    let inactivo = parametrosURL.get('inactivo')

    let configuracion = {
        // logo: Logo,
        // logoFooter: null,
        colorPrincipal: '#816518',
        colorSecundario: '#d6ab32',
        colorTexto: '#FFF',
        key: config.keyAPICrufdek,
        tabla: 'EurekaAcUsuario',
        redireccion: `${window.location.origin}/procesar_credenciales`,
        inactivo: inactivo === null ? 0 : inactivo,
        aceptaRegistros: 0,
        // serviciosAccesoMostrar: ['google', 'microsoft', 'correo']
    }

    return (
        <AccountEurekaSigma config={configuracion} />
    )
}

export default InicioSesion
