import { Navegador, Theme } from "eureka-design";
import logo from "../../img/icono.png"
import Seminarios from "../seminarios/Seminarios";
import Usuarios from "../usuarios/Usuarios";
import Usuario from "../usuarios/Usuario";
import FormSeminario from "../seminarios/FormSeminario";
import FormUsuario from "../usuarios/FormUsuario";
import Seminario from "../seminarios/Seminario";
import Perfil from "../perfil/Perfil";
import Membresias from "../membresias/Membresias";
import CuentasFiscales from "../perfil/CuentasFiscales";
import FormCuentaFiscal from "../perfil/FormCuentaFiscal";
import { useSelector } from "react-redux";
import { capitalize, obtenerFoto, obtenerRol } from "../../js/util";
import FormMembresia from "../membresias/FormMembresia";
import { useObtenerUsuarioQuery } from "../../js/service/usuario";
import Membresia from "../membresias/Membresia";
import Temas from "../temas/Temas";
import SeminarioTemas from "../temas/SeminariosTema";


const COLOR_PRIMARIO = '#816518'
const COLOR_SECUNDARIO = '#816518'

export default function Index() {
    const sesionSlice = useSelector(state => state.sesionSlice)
    const { data: usuario, isLoading: cargandoUsuario } = useObtenerUsuarioQuery({
        token: sesionSlice.token,
        columna: 'id',
        valor: sesionSlice.user.id
    })

    if (!cargandoUsuario) {
        let rol = usuario ? obtenerRol(usuario) : ''

        let componenteSeminario = {
            'Seminarios': {
                opcion: {
                    texto: 'Seminarios',
                    icono: 'fa-file-video'
                },
                pantallas: {
                    "seminario-enterpreneour": {
                        props: {},
                        titulo: "Seminarios",
                        componente: Seminarios,
                    },
                    "seminario": {
                        props: {},
                        titulo: "Seminario",
                        componente: Seminario,
                    },
                    formseminario: {
                        props: {},
                        titulo: "Seminario",
                        componente: FormSeminario
                    }
                }
            }
        }
        let componenteTema = {
            'Temas': {
                opcion: {
                    texto: 'Temas',
                    icono: 'fa-tag'
                },
                pantallas: {
                    'temas': {
                        props: {},
                        titulo: 'Temas',
                        componente: Temas
                    },
                    'seminarios_temas': {
                        props: {},
                        titulo: 'Jerarquia Seminarios',
                        componente: SeminarioTemas
                    }
                }
            }
        }
        let componenteUsuario = {
            'Usuarios': {
                opcion: {
                    texto: 'Usuarios',
                    icono: 'fa-users'
                },
                pantallas: {
                    usuarios: {
                        props: {},
                        titulo: "Usuarios",
                        componente: Usuarios,
                    },
                    usuario: {
                        props: {},
                        titulo: "Usuario",
                        componente: Usuario,
                    },
                    formusuario: {
                        props: {},
                        titulo: "Usuario",
                        componente: FormUsuario,
                    }
                }
            }
        }
        let componenteMembresia = {
            "Membresias": {
                opcion: {
                    texto: 'Membresias',
                    icono: 'fa-credit-card'
                },
                pantallas: {
                    membresias: {
                        props: {},
                        titulo: "Membresias",
                        componente: Membresias,
                    },
                    form_membresias: {
                        props: {},
                        titulo: "Membresia",
                        componente: FormMembresia,
                    },
                    membresia: {
                        props: {},
                        titulo: "Membresia",
                        componente: Membresia,
                    },
                },
            }
        }

        let componenteRol = null
        if (rol === 'administrador') {
            componenteRol = {
                ...componenteSeminario,
                ...componenteTema,
                ...componenteUsuario,
                ...componenteMembresia,
            }
        }
        else if (rol === 'vendedor') {
            componenteRol = {
                ...componenteUsuario,
            }
        }
        else if (rol === 'capturista') {
            componenteRol = {
                ...componenteSeminario,
            }
        }
        else {
            componenteRol = {}
        }

        let componente = {
            ...componenteRol,
            'Perfil': {
                pantallas: {
                    miperfil: {
                        props: { usuario: usuario },
                        titulo: "Mi perfil",
                        componente: Perfil,
                    },
                    editar_perfil: {
                        props: {},
                        titulo: "Editar perfil",
                        componente: FormUsuario,
                    },
                    cuentas_fiscales: {
                        props: { usuario },
                        titulo: "Cuentas fiscales",
                        componente: CuentasFiscales,
                    },
                    form_cuenta_fiscal: {
                        props: { usuario },
                        titulo: "Cuenta fiscal",
                        componente: FormCuentaFiscal,
                    }
                },
            },
        }

        return (
            <>
                <Theme
                    lightColor={COLOR_PRIMARIO}
                    darkColor={COLOR_SECUNDARIO}
                >
                    <Navegador
                        themeDefault="dark"
                        empresa={{
                            nombre: "TrainingWorldWeb",
                            imagen: logo,
                            version: "v0.0.2310261 demo",
                        }}
                        usuario={{
                            nombre: capitalize(usuario.nombre ?? 'Desconocido'),
                            rol: capitalize(rol ?? 'Desconocido'),
                            imagen: obtenerFoto(usuario),
                            pantalla: "Perfil"
                        }}
                        componentes={componente}
                    ></ Navegador>
                </Theme >
            </>
        )
    }
}