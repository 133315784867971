import { NotFound as NF } from "eureka-design"

const NotFound = () => {
    return (
        <NF
            mensaje="La pagina solicitada no se encontro"
            codigoError="404"
        >
        </NF>
    )
}

export default NotFound