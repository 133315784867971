import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { fijaToken, fijaUsuario } from "../js/feactures/SesionSlice"
import { DIA, crearCookie } from "account-react-eurekasigma"
import { useObtenerUsuarioQuery } from "../js/service/usuario"
import { CargandoPagina } from "eureka-design"
import { config } from "../config/config"

const ProcesarCredenciales = () => {
    /** ESTADOS */
    const dispatch = useDispatch()
    const navegacion = useNavigate()
    const parametrosURL = new URLSearchParams(window.location.search)
    const correo = parametrosURL.get('correo')
    const token = parametrosURL.get('token')
    const { data: usuario, isLoading, error } = useObtenerUsuarioQuery({
        columna: 'correo', 
        valor: correo, 
        token: token
    })
    
    if(error && error.status === 401) navegacion('/terminar_sesion')

    useEffect(() => {
        if(!isLoading) {
            if(usuario === undefined) navegacion('/iniciar_sesion')
            else {
                crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, { tiempo: 15, tipo: DIA })
                crearCookie(config.nomCookieToken, token, true, { tiempo: 15, tipo: DIA })
                dispatch(fijaUsuario(usuario))
                dispatch(fijaToken(token))
                navegacion('/')
            }    
        }
        // else sigue cargando los datos del usuario
    }, [navegacion, usuario, isLoading, token, dispatch])

    return (
        <CargandoPagina
            mensaje="Redirigiendo, por favor espere..."
            colorPrincipal="#816518"
        ></CargandoPagina>
    )
}

export default ProcesarCredenciales