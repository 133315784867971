import { configureStore } from "@reduxjs/toolkit";
import handlerQuerySlice from "../js/feactures/HandlerQuerySlice";
import sesionReducer from "../js/feactures/SesionSlice";
import { usuarioApi } from "../js/service/usuario";
import { rolApi } from "../js/service/rol";
import { membresiaApi } from "../js/service/membresia";
import { seminarioApi } from "../js/service/seminario";
import { temaApi } from "../js/service/tema";
import { fiscalApi } from "../js/service/fiscal";
import { cuentaFiscalApi } from "../js/service/fiscal";

export const store = configureStore({
    reducer: {
        handlerQuerySlice: handlerQuerySlice,
        sesionSlice: sesionReducer,
        [usuarioApi.reducerPath]: usuarioApi.reducer,
        [rolApi.reducerPath]: rolApi.reducer,
        [membresiaApi.reducerPath]: membresiaApi.reducer,
        [seminarioApi.reducerPath]: seminarioApi.reducer,
        [temaApi.reducerPath]: temaApi.reducer,
        [fiscalApi.reducerPath]: fiscalApi.reducer,
        [cuentaFiscalApi.reducerPath]: cuentaFiscalApi.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false }),
        usuarioApi.middleware,
        rolApi.middleware,
        membresiaApi.middleware,
        seminarioApi.middleware,
        temaApi.middleware,
        fiscalApi.middleware,
        cuentaFiscalApi.middleware,
    ],
})