

class EkQuery {
    constructor(modelo) {
        this.Query = {
            modelo: modelo,
        };
    }

    search(busqueda, columnas) {
        if (typeof busqueda == "string" && Array.isArray(columnas)) {
            this.Query.busqueda = {
                busqueda: busqueda,
                columnas: columnas
            }
        }
        return this
    }

    with(relacion) {
        if (!this.Query.relaciones) {
            this.Query.relaciones = [];
        }
        if (typeof relacion === "string") {
            this.Query.relaciones.push({
                relacion: relacion
            })
        }
        if (typeof relacion === "array") {
            relacion.forEach(item => {
                this.Query.relaciones.push({
                    relacion: item
                })
            });
        }
        return this
    }

    where(param1, param2, param3) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }

        if (typeof param1 === "string" && param2 && !param3) {

            this.Query.condiciones.push(
                {
                    tipo: "where",
                    columna: param1,
                    operador: "=",
                    condicion: param2
                }
            )
        }

        if (typeof param1 === "string" && typeof param2 === "string" && param3) {

            this.Query.condiciones.push(
                {
                    tipo: "where",
                    columna: param1,
                    operador: param2,
                    condicion: param3
                }
            )
        }

        if (typeof param1 === "array" && !param2 && !param3) {

            // param1.forEach(condicion => {
            //     this.Query.condiciones.push(
            //         {
            //             tipo: "where",
            //             columna: param1,
            //             operador: param2,
            //             condicion: param3
            //         }
            //     )
            // });

        }

        return this
    }

    create(datos) {
        this.Query.tipo = "Crear";
        this.Query.datos = [];
        Object.entries(datos).forEach(([key, value]) => {
            this.Query.datos.push({
                columna: key,
                dato: value,
            })
        });
        this.JsonParams()
        return this.Query;
    }

    uploadFile(file, carpeta, nombre) {

        this.Query.tipo = "Archivos";
        this.Query.datos = [{
            columna: nombre,
            carpeta: carpeta,
        }];
        if (carpeta) {
            this.Query.datos.push(
                {
                    columna: "carpeta",
                    dato: carpeta,
                })
        }
        this.Query.file = file;
        this.JsonParams()
        return this.Query;
    }

    update(datos) {
        this.Query.tipo = "Editar";
        this.Query.datos = [];
        Object.entries(datos).forEach(([key, value]) => {
            this.Query.datos.push({
                columna: key,
                dato: value,
            })
        });
        this.JsonParams()
        return this.Query;
    }

    get() {
        this.Query.peticion = "Todos"
        this.JsonParams()
        return this.Query;
    }

    first() {
        this.Query.peticion = "Primero"
        this.JsonParams()
        return this.Query;
    }

    paginate(limit, page) {
        this.Query.peticion = "Paginado"
        this.Query.paginado = JSON.stringify({
            perpage: limit,
            current_page: page,
        })
        this.JsonParams()
        return this.Query;
    }

    JsonParams() {
        if (this.Query.busqueda) this.Query.busqueda = JSON.stringify(this.Query.busqueda);
        if (this.Query.condiciones) this.Query.condiciones = JSON.stringify(this.Query.condiciones);
        if (this.Query.relaciones) this.Query.relaciones = JSON.stringify(this.Query.relaciones);
        if (this.Query.orden) this.Query.orden = JSON.stringify(this.Query.orden);
        if (this.Query.datos) this.Query.datos = JSON.stringify(this.Query.datos);
    }
}

export { EkQuery };