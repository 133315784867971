import { createSlice } from "@reduxjs/toolkit"

const handlerQuerySlice = createSlice({
    name: 'handlerQuerySlice',
    initialState: {},
    reducers: {
        setHandler: (state, actions) => {
            const payload = actions.payload
            state[payload.state] = {
                ...state[payload.state],
                ...payload.content
            }
        }
    }
})

export const { setHandler } = handlerQuerySlice.actions
export default handlerQuerySlice.reducer