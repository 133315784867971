import nouser from "../img/nouser.jpg"
import { config } from "../config/config"

export const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

export const CICLOS_MEMBRESIA = [
    { id: 1, tipo: 'Diaria' },
    { id: 2, tipo: 'Mensual' },
    { id: 3, tipo: 'Bimestral' },
    { id: 4, tipo: 'Trimestral' },
    { id: 5, tipo: 'Semestral' },
    { id: 6, tipo: 'Anual' }
]
export const handlerMutation = async (mutacion, body, endQuery, errorQuery) => {
    if (mutacion && typeof mutacion === "function") {
        return await mutacion(body).unwrap()
            .then(endQuery ? endQuery : (data) => { })
            .catch((error) => { if (error?.status === 401) errorQuery ? errorQuery(error) : console.log(error) })
    }
}

export const capitalize = (cadena = '') => {
    if (cadena) {
        cadena = cadena.toLowerCase()
        let firstLetter = cadena.charAt(0)
        let firstLetterCap = firstLetter.toUpperCase()
        let remainingLetters = cadena.slice(1)
        return firstLetterCap + remainingLetters
    }
    else {
        return ''
    }
}

export const obtenerFoto = (usuario = {}, imagenError = null) => {
    if (usuario?.eureka_cr_multimedia) {
        let multimedias = usuario.eureka_cr_multimedia

        if (Array.isArray(multimedias)) {
            if (multimedias.length !== 0) {
                let multimedia = multimedias[0]
                return `${config.crufdekFileURL}/${multimedia.carpeta}/${multimedia.nombre}`
            }
            else {
                return imagenError ? imagenError : nouser
            }
        }
        else {
            return `${config.crufdekFileURL}/${multimedias.carpeta}/${multimedias.nombre}`
        }
    }
    else {
        return imagenError ? imagenError : nouser
    }
}

export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = '$') => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for (let i = numberIntStr.length - 1; i >= 0; i--) {
        if (digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }

        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if (dollarSimb) {
        return `${dollarSimb}${numberStr}`
    }
    else {
        return numberStr
    }
}

export const adapatarDataTabla = (data = [], config = []) => {
    if (data.length === 0 || config.length === 0) return data
    else {
        return data.map((dato) => {
            let datoAdaptado = {
                ...dato
            }
            let valor = null
            let profundidades = null
            let profundidad = null
            let j = null

            for (let i = 0; i < config.length; i++) {
                profundidades = config[i].profundidad

                for (j = 0; j < profundidades.length; j++) {
                    profundidad = profundidades[j]
                    if (valor) {
                        valor = (profundidad === 'array') ? valor.length : valor[profundidad]
                    }
                    else {
                        valor = datoAdaptado[profundidad]
                    }
                }
                datoAdaptado[config[i].nomNuevoCampo] = valor
                valor = null
            }

            return datoAdaptado
        })
    }
}

export const adaptarDatosSelect = (data = [], colValue = '', colText = '') => {
    let dataNew = []
    data.map((d) => {
        dataNew.push({
            value: d[colValue],
            text: d[colText]
        })
        return true
    })
    return dataNew
}

export const obtenerArchivo = (seminario = null, tipo = 'pdf') => {
    if (seminario?.eureka_st_productos_fotos?.length > 0) {
        let archivos = seminario.eureka_st_productos_fotos
        archivos = archivos.filter((archivo) => {
            return archivo.eureka_cr_multimedia.carpeta === tipo
        })
        if (archivos.length === 1) return archivos[0].eureka_cr_multimedia
        else return archivos
    }
    else return null
}

export const obtenerRol = (usuario = {}) => {
    if (usuario?.eureka_cr_rol_usuarios[0]?.eureka_cr_rol) {
        return usuario.eureka_cr_rol_usuarios[0].eureka_cr_rol.nombre
    }
    else {
        return ''
    }
}

export const obtenerMembresia = (usuario = {}) => {
    if (usuario?.eureka_pa_subscriptores[0]?.eureka_pa_subscripcione) {
        return usuario.eureka_pa_subscriptores[0].eureka_pa_subscripcione.nombre
    }
    else {
        return 'Sin membresia'
    }
}