import { Tabla, buildData, Miniatura } from "eureka-design"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useDispatch, useSelector } from "react-redux"
import { FORM_USUARIO } from "./FormUsuario"
import { useObtenerUsuariosQuery } from "../../js/service/usuario"
import { useEffect, useState } from "react"
import { obtenerFoto, obtenerMembresia, obtenerRol } from "../../js/util"

export default function Usuarios({ Push, ChangeScene, ShowAlert }) {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** ESTADOS */
    const dispatch = useDispatch()
    const [usuariosFilt, fijarUsuarios] = useState([])

    /** HOOK */
    const {data: usuarios, isLoading: cargandoUsuarios} = useObtenerUsuariosQuery({token: sesionSlice.token})

    useEffect(() => {
        if(!cargandoUsuarios) {
            fijarUsuarios(usuarios.filter((usuario) => {
                return obtenerRol(usuario) !== 'administrador'
            }))
        }
        // else esperar a que carguen los registros usuarios
    }, [cargandoUsuarios, usuarios])

    if(cargandoUsuarios) {
        return
        // return <p>Cargando usuarios, por favor espere...</p>
    }
    else {
        return (
            <Tabla
                noData="Aún no hay usuarios"
                rowSize="medium"
                checkbox={false}
                filtros={[
                    { text: "Todos", filtro: () => true },
                    { text: "Nivel 1", key: "membresia", filtro: item => item.value === "Enterpreneour" },
                    { text: "Nivel 2", key: "membresia", filtro: item => item.value === "Insurance" },
                    { text: "Nivel 3", key: "membresia", filtro: item => item.value === "Human" },
                ]}
                onClickRow={(item) => {
                    Push('usuario', {usuarioID: item.id}) 
                }}
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                headers={[
                    { text: "", key: "foto", type: "text", size: "small" },
                    { text: "Nombre", key: "nombre", type: "text" },
                    { text: "Correo", key: "correo", type: "text" },
                    { text: "Teléfono", key: "telefono", type: "text" },
                    { text: "Membresia", key: "membresia", type: "text" },
                ]}
                data={
                    buildData(usuariosFilt, [
                        {
                            key: 'foto', type: 'text', size: 'small', text: (item) => {
                                return (
                                    <Miniatura
                                        size="medium"
                                        src={obtenerFoto(item)}
                                    ></Miniatura>
                                )
                            }
                        },
                        { key: 'nombre', type: 'text' },
                        { key: 'correo', type: 'text' },
                        { key: 'telefono', type: 'telefono' },
                        { 
                            key: 'membresia', 
                            type: 'text',
                            text: (item) => obtenerMembresia(item),
                            value: (item) => obtenerMembresia(item),
                        },
                    ])
                }
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Crear',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_USUARIO,
                                content: {
                                    id: null,
                                }
                            }))
                            Push('formusuario')
                        }
                    }
                ]}
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_USUARIO,
                                content: {
                                    id: item.id,
                                }
                            }))
                            Push('formusuario')
                        }
                    },
                    {
                        icono: 'fa-solid fa-trash-can',
                        tooltip: 'Archivar',
                        onClick: (item) => {
                            console.log(item)
                        }
                    }
                ]}
            />
        )
    }
}
