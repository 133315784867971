import { eliminarCookie } from "account-react-eurekasigma"
import { CargandoPagina } from "eureka-design"
import { config } from "../config/config"

const TerminarSesion = () => {    
    eliminarCookie(config.nomCookieUsuario)
    eliminarCookie(config.nomCookieToken)
    window.location.href = `${window.location.origin}/iniciar_sesion?inactivo=1`

    return (
        <CargandoPagina
            mensaje="Redirigiendo, por favor espere..."
            colorPrincipal="#816518"
        ></CargandoPagina>
    )
}

export default TerminarSesion