import { Tabla, buildData, ChipEstatus } from "eureka-design"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useDispatch, useSelector } from "react-redux"
import { FORM_SEMINARIO } from "./FormSeminario"
import { useObtenerSeminariosQuery } from "../../js/service/seminario"

export default function Seminarios({ Push, ChangeScene, ShowAlert }) {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const dispatch = useDispatch()
    const { data: seminarios, isLoading: cargandoSeminarios, error: errorCargarSeminarios } = useObtenerSeminariosQuery({ 
        token: sesionSlice.token
    })

    if(errorCargarSeminarios) {
        return <p>Sucedio el siguiente error al obtener la información: {errorCargarSeminarios}</p>
    }
    else if(cargandoSeminarios) {
        return
        // return <p>Cargando seminarios, por favor espere...</p>
    }
    else {
        return (
            <Tabla
                noData="Aún no hay seminarios"
                checkbox={false}
                filtros={[
                    { text: "Todos", filtro: () => true },
                    {
                        text: "Activos",
                        key: "estatus",
                        filtro: item => item.value === 'activo'
                    },
                    {
                        text: "Archivados",
                        key: "estatus",
                        filtro: item => item.value === 'archivado'
                    },
                ]}
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                headers={[
                    { text: "Nombre", key: "nombre", type: "text" },
                    { text: "Fecha", key: "fecha_inicio", type: "date" },
                    { text: "Estatus", key: "estatus", type: "text" },
                ]}
                onClickRow={(item) => {
                    dispatch(setHandler({
                        state: FORM_SEMINARIO,
                        content: { id: item.id }
                    }))
                    Push("seminario") 
                }}
                data={
                    buildData(seminarios, [
                        { key: 'nombre', type: 'text' },
                        { key: 'fecha_inicio', type: 'date' },
                        {
                            key: 'estatus', type: 'text', text: (item) => {
                                if (item.estatus === "activo") {
                                    return <ChipEstatus texto={"Activo"} tipo="Aceptado"></ChipEstatus>
                                }
                                if (item.estatus === "archivado") {
                                    return <ChipEstatus texto={"Archivado"} tipo="Error"></ChipEstatus>
                                }
                            }
                        },
                    ]
                    )}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Crear',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_SEMINARIO,
                                content: { id: null }
                            }))
                            Push('formseminario')
                        }
                    }
                ]}
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            dispatch(setHandler({
                                state: FORM_SEMINARIO,
                                content: { id: item.id }
                            }))
                            Push('formseminario')
                        }
                    },
                    {
                        icono: 'fa-solid fa-trash-can',
                        tooltip: 'Archivar',
                        onClick: (item) => {
                            console.log(item)
                        }
                    },
                    // {
                    //     icono: 'fa-solid fa-arrow-up',
                    //     tooltip: 'Subir',
                    //     onClick: (item) => {
                    //         console.log(item)
                    //     }
                    // },
                    // {
                    //     icono: 'fa-solid fa-arrow-down',
                    //     tooltip: 'Bajar',
                    //     onClick: (item) => {
                    //         console.log(item)
                    //     }
                    // }
                ]}
            />
        )
    }
}
