import { Formulario, Input, VideoPlayer, DropFile, ProgressBar, ModalForm, Chip } from "eureka-design"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { ProcesadorMultimedia } from "../../js/ProcesadorMultimedia"
import { config } from "../../config/config"
import { 
    useCrearSeminarioMutation, 
    useGuardarPDFMutation, 
    useObtenerSeminarioQuery, 
    useObtenerTemasQuery, 
    useRelacionarMultimediaMutation,
    RELACION_EVENTO_MULTIMEDIA,
    useRelacionarTemaMutation,
} from "../../js/service/seminario"

export const FORM_SEMINARIO = 'form_seminario'
const TIEMPO_INTERVALO = 1000
const COMPLETADO = 100

export default function FormSeminario({ ShowAlert }) {
    /** ESTADOS GLOBALES */
    const formSeminario = useSelector(state => state.handlerQuerySlice[FORM_SEMINARIO])
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const dispatch = useDispatch()
    const { data: seminario, isLoading: cargandoSeminario } = useObtenerSeminarioQuery({
        token: sesionSlice.token,
        id: formSeminario.id,
    })
    const { data: temas, isLoading: cargandoTemas } = useObtenerTemasQuery({ token: sesionSlice.token })
    const [
        crearSeminario,
        {
            isLoading: actualizandoSeminario,
            isError: errorActulizarSeminario,
            isSuccess: seminarioActualizado,
        }
    ] = useCrearSeminarioMutation()
    const [
        guardarPDF,
        {
            isLoading: actualizandoPDF,
            isError: errorActulizarPDF,
            isSuccess: pdfActualizado,
        }
    ] = useGuardarPDFMutation()
    const [ crearRelacion ] = useRelacionarMultimediaMutation()
    const [ crearRelacionTema ] = useRelacionarTemaMutation()

    /** ESTADOS */
    const [multimedia, fijaMultimedia] =                         useState(null)
    const [procesadorMultimedia] =                               useState(new ProcesadorMultimedia())
    const [progreso, fijaProgreso] =                             useState(0)
    const [progresoCarga, fijaProgresoCarga] =                   useState(0)
    const [mostrarModalProgreso, fijaMostrarModalProgreso] =     useState(false)
    const [tokenMonitoreoProgreso, fijaTokenMonitoreoProgreso] = useState(null)
    const [seRealizoConversion, cambiarEstadoConversion] =       useState(false)
    const [esCreacion] =                                         useState(formSeminario.id === null)
    const [seminarioID, fijaSeminarioID] =                       useState(formSeminario.id)
    const [multimediaPdfId, fijaMultimediaPdfId] =               useState(null)
    const [nomPDF, fijaNomPDF] =                                 useState('')
    const [srcMultimedia, fijaSrcMultimedia] =                   useState('')
    const [temasSeminario, fijaTemasSeminario] =                 useState([])

    useEffect(() => {
        const obtenerArchivo = (registroSeminario = null, tipo = 'pdf') => {
            if(registroSeminario) {
                if(registroSeminario[RELACION_EVENTO_MULTIMEDIA]?.length > 0) {
                    let archivos = registroSeminario[RELACION_EVENTO_MULTIMEDIA]
                    archivos = archivos.filter((archivo) => {
                        return archivo.eureka_cr_multimedia.carpeta === tipo
                    })
                    if(archivos.length === 1) return archivos[0].eureka_cr_multimedia
                    else return archivos
                }
                else return null
            }
            else return null
        }

        if(!cargandoSeminario && !cargandoTemas) {
            let multimedia = seminario?.eureka_cr_multimedia
            let pdf = obtenerArchivo(seminario, 'pdf')
            
            if(multimedia) {
                fijaSrcMultimedia(`${config.crufdekFileURL}/${multimedia.carpeta}/${multimedia.nombre}/video`)
            }
            // else no existe algún archivo multimedia
            if(pdf) {
                fijaNomPDF(pdf.nombre)
                fijaMultimediaPdfId(pdf.id)
            }
            // else no existe algún archivo pdf

            fijaTemasSeminario(seminario?.eureka_bs_eventos_intereses ?? [])
        }
        // else continuar con otras tareas

        procesadorMultimedia.generarToken().then(token => {
            fijaTokenMonitoreoProgreso(token)
        })
    }, [
        procesadorMultimedia, 
        fijaTokenMonitoreoProgreso, 
        cargandoSeminario, 
        seminario, 
        fijaSrcMultimedia,
        //fijaMultimediaId,
        fijaNomPDF,
        fijaMultimediaPdfId,
        cargandoTemas,
        temas,
    ])

    /** MANEJADORES */
    async function handlerSeminario(nom, val, id = null) {
        return await crearSeminario({
            id: id,
            token: sesionSlice.token,
            body: { [nom]: val, 'tipo': 'membresia' },
        })
        .unwrap()
        .then(response => {
            if(response?.mensaje?.id) {
                id = response.mensaje.id
                fijaSeminarioID(id)
            }
            // else se actualizo el registro
            return id
        })
    }

    const handlerInput = async (val, nom) => {
        let semID = await handlerSeminario(nom, val, seminarioID)

        if(!seminario?.fecha_alta) {
            let fechaAlta = (new Date()).toISOString().split('T')
            handlerFormData('fecha_inicio', fechaAlta[0])
            handlerSeminario('fecha_inicio', fechaAlta[0], semID)
        }
        // else no guardar la fecha
        handlerFormData(nom, val)
    }

    const handlerPDF = async (val, nom) => {
        let semID = null
        if(seminarioID) semID = seminarioID
        else semID = await handlerSeminario('nombre', '')

        guardarPDF({
            token: sesionSlice.token,
            id: multimediaPdfId,
            configFile: {
                file: val,
                carpeta: 'pdf',
                nombre: 'nombre',
            },
        })
        .unwrap()
        .then((response) => {
            if(response?.mensaje?.id) {
                fijaMultimediaPdfId(response.mensaje.id)
                handlerFormData('pdf', response.mensaje.id)
                crearRelacion({
                    token: sesionSlice.token,
                    id: null,
                    body: { archivo: response.mensaje.id, eventos: semID }
                })
            }
            // else el registro se actualizo
            handlerFormData('nomPDF', val.name)
        })
    }

    const handlerFormData = (key, val) => {
        dispatch(
            setHandler({
                state: FORM_SEMINARIO,
                content: {
                    id: seminarioID,
                    [key]: val
                }
            })
        )
    }

    const handlerMultimedia = async (respSuccess) => {
        fijaProgreso(COMPLETADO)
        //fijaMultimediaId(respSuccess.id)
        handlerFormData('multimediaId', respSuccess.id)
        handlerFormData('srcMultimedia', respSuccess.nombre)
        handlerSeminario('video_grabado', respSuccess.id, seminarioID)
    }

    const handlerChip = (temaID = null, key = null, cambiarEstado = () => {}) => {
        let relacionesTemas = temasSeminario.filter((temaSeminario) => {
            return temaSeminario?.interes === temaID
        })

        if(relacionesTemas.length > 0) { // eliminar relacion
            let relacion = relacionesTemas[0]
            crearRelacionTema({ 
                token: sesionSlice.token, 
                id: relacion.id,
                body: { 'eventos': null }
            })
            .unwrap()
            .then((res) => {
                relacionesTemas = temasSeminario.filter((temaSeminario) => {
                    return temaSeminario?.interes !== temaID
                })
                fijaTemasSeminario(relacionesTemas)
            })
            cambiarEstado(false)
        }
        else { // crear relacion
            crearRelacionTema({
                token: sesionSlice.token,
                body: { 'eventos': seminarioID, 'interes': temaID }
            })
            .unwrap()
            .then((res) => {
                relacionesTemas = [...temasSeminario]
                relacionesTemas.push(res.mensaje)
                fijaTemasSeminario(relacionesTemas)
            })
            cambiarEstado(true)
        }
    }

    /** CONVERTIDOR DE MULTIMEDIA */
    const convertirMultimedia = (tipo = 'video') => {
        let formato = (tipo === 'video') ? 'mp4' : 'mp3'
        let carpeta = (tipo === 'video') ? 'videos' : 'videos'
        fijaMostrarModalProgreso(true)
        procesadorMultimedia.convertirVideo(
            multimedia, 
            tokenMonitoreoProgreso, 
            formato, 
            carpeta, 
            'EurekaCrMultimedia',
            config.keyAPICrufdek,
            (p) => { fijaProgresoCarga(p) },
            handlerMultimedia
        )

        let intervalID = setInterval(() => {
            procesadorMultimedia.dameProgresoCoversionVideo(tokenMonitoreoProgreso).then(p => {
                let resp = procesadorMultimedia.dameReponse()

                if(p === COMPLETADO || resp || progreso === COMPLETADO) {
                    if(resp !== null) {
                        fijaProgreso(COMPLETADO)
                        procesadorMultimedia.fijaReponse(null)
                        fijaSrcMultimedia(`${config.crufdekFileURL}/${carpeta}/${resp.nombre}/video`)
                        cambiarEstadoConversion(true)
                        clearInterval(intervalID)

                        ShowAlert({
                            icono: 'success',
                            titulo: 'Finalizo la conversión',
                            time: 7,
                            mensaje: 'Ahora puede cerrar la ventana y vizualizar su archivo convertido en la parte superior'
                        })
                    }
                    // else la resp no se a actualizado
                }
                else fijaProgreso(p)
            })
        }, TIEMPO_INTERVALO)
    }

    if(cargandoSeminario || cargandoTemas) {
        return (
            <p>Cargando, por favor espere...</p>
            // <CargandoPagina
            //     mensaje="Cargando, por favor espere..."
            //     colorPrincipal="#816518"
            // ></CargandoPagina>
        )
    }
    else {
        return (
            <>
                <ModalForm
                    show={mostrarModalProgreso}
                    onClose={() => {
                        if(progreso === 100) {
                            fijaMostrarModalProgreso(false)
                            fijaProgreso(0)
                        }
                        else {
                            ShowAlert({
                                icono: 'info',
                                titulo: 'Multimedia convirtiendose',
                                time: 3,
                                mensaje: 'espere a que la conversión termine para cerrar la ventana'
                            })
                        }
                    }}
                >
                    <p><b>Paso 1:</b> Subiendo multimedia</p>
                    <ProgressBar progress={progresoCarga} size="large" />

                    <p><b>Paso 2:</b> Convirtiendo multimedia</p>
                    <ProgressBar progress={progreso} size="large" />
                </ModalForm>
                
                <Formulario
                    titulo={`${esCreacion ? 'Crear' : 'Editar'} Seminario`}
                    isLoading={[actualizandoSeminario, actualizandoPDF]}
                    isError={[errorActulizarSeminario, errorActulizarPDF]}
                    isSuccess={[seminarioActualizado, pdfActualizado]}
                    botones={[
                        {
                            texto: 'Convertir archivo multimedia',
                            onClick: () => {
                                if(multimedia) {
                                    if(!seRealizoConversion) {
                                        convertirMultimedia(multimedia.type.split('/')[0])
                                    }
                                    else {
                                        ShowAlert({
                                            icono: 'error',
                                            titulo: 'Archivo multimedia convertido',
                                            time: 2,
                                            mensaje: 'El archivo ya se a convertido.'
                                        })
                                    }
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Archivo multimedia no encontrado',
                                        mensaje: 'Para iniciar la conversión seleccione un archivo multimedia.'
                                    })
                                }
                            }
                        }
                    ]}
                >
                    <VideoPlayer src={srcMultimedia} />
                    <DropFile
                        nombre="multimedia"
                        allowedTypes={['video/*', 'audio/*']}
                        onChange={(value, nombre) => {
                            fijaSrcMultimedia(URL.createObjectURL(value))
                            fijaMultimedia(value)
                        }}
                    ></DropFile>

                    <Input
                        type="text"
                        nombre="nombre"
                        placeholder="Nombre"
                        iconLeft="fa-solid fa-file-alt"
                        value={seminario?.nombre}
                        changeFunction={handlerInput}
                        isLoading={actualizandoSeminario}
                    />
                    <Input
                        type="textarea"
                        nombre="descripcion"
                        placeholder="Descripción"
                        iconLeft="fa-solid fa-file-alt"
                        value={seminario?.descripcion}
                        changeFunction={handlerInput}
                        isLoading={actualizandoSeminario}
                    />
                    <Input
                        type="file"
                        nombre="pdf"
                        placeholder="PDF adjunto"
                        iconLeft="fa-solid fa-file-pdf"
                        accept=".pdf"
                        value={nomPDF}
                        changeFunction={handlerPDF}
                        isLoading={actualizandoPDF}
                    />

                    <h3>Temas</h3>
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {
                            temas.map((tema, index) => {
                                let filtro = temasSeminario?.filter((temaSeminario) => {
                                    return temaSeminario?.eureka_in_interese?.id === tema.id
                                })

                                return (
                                    <Chip
                                        key={index}
                                        selected={filtro.length > 0}
                                        texto={tema.nombre} 
                                        onClick={(cambiarEstado) => {
                                            handlerChip(tema.id, index, cambiarEstado)
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                </Formulario>
            </>
        )
    }
}