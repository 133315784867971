import { Tabla, buildData, ChipEstatus } from "eureka-design"
import { useSelector } from "react-redux"
import { useObtenerTemasQuery } from "../../js/service/seminario"

export default function Temas({ Push, ChangeScene, ShowAlert }) {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** HOOK */
    const { data: temas, isLoading } = useObtenerTemasQuery({ token: sesionSlice.token })

    if(isLoading) return
    else {
        return (
            <Tabla
                noData="Aún no hay temas para mostrar"
                rowSize="medium"
                checkbox={false}
                busqueda={{ placeholder: 'Buscar...', keys: ['nombre'] }}
                keys={[
                    { text: "Todos", filtro: () => true },
                ]}
                botonesHead={[
                    // {
                    //     icono: 'fa-solid fa-plus',
                    //     tooltip: 'Agregar',
                    //     onClick: (item) => {
                    //         console.log('agregar tema...')
                    //     }
                    // }
                ]}
                headers={[
                    { text: "Nombre", key: "nombre", type: "text" },
                    { text: "Estatus", key: "estatus", type: "text" },
                ]}
                data={
                    buildData(temas, [
                        { key: 'nombre', type: 'text' },
                        {
                            key: 'estatus', 
                            type: 'text', 
                            text: (item) => <ChipEstatus texto={"Activo"} tipo="Aceptado"></ChipEstatus>
                        }
                    ])
                }
                onClickRow={(item) => {
                    Push('seminarios_temas', { tema: item.item })
                }}
                botonesRow={[
                    // {
                    //     icono: 'fa-solid fa-file-edit',
                    //     tooltip: 'Editar',
                    //     onClick: (item) => {
                    //         console.log('Editar tema...')
                    //     }
                    // },
                    // {
                    //     icono: 'fa-solid fa-trash-can',
                    //     tooltip: 'Archivar',
                    //     onClick: (item) => {
                    //         console.log('Archivar tema...')
                    //     }
                    // }
                ]}
            />
        )
    }
}