export const config = {
    keyAPICrufdek: 'J1NDsiLkRNgTAgwa9cye144SKq4AaRlFtLxa8RCv',
    crufdekURLV1: 'https://crud.eksuite.com/api/v1/CRUFDEK',
    crufdekURL: 'https://crud.eksuite.com/api/v2/CRUFDEK',
    crufdekFileURL: 'https://crud.eksuite.com/api/GetFile',
    dataBaseVersionURL: 'https://crud.eksuite.com/api',
    roles: ['administrador', 'capturista', 'vendedor'],
    temasIniciales: ['Enterprenour', 'Insurance', 'Human Factor'],
    nomCookieUsuario: `usuario${window.location.href.includes('localhost') ? 'Local' : 'Web'}`,
    nomCookieToken: `tokenSesion${window.location.href.includes('localhost') ? 'Local' : 'Web'}`
}