import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_ROL = "EurekaCrRol"
const TABLA_RELACION_ROL = "EurekaCrRolUsuario"

export const rolApi = createApi({
    reducerPath: 'rolApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRoles: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_ROL).get()
                }
            },
        }),
        crearRoles: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_ROL).create({ nombre: data.nombre })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearRelacionRol: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_RELACION_ROL)
                                .create({
                                    rol: data.rolID,
                                    usuario: data.usuarioID
                                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})

export const {
    useObtenerRolesQuery,
    useCrearRolesMutation,
    useCrearRelacionRolMutation,
} = rolApi