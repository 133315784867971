import { CardEvento } from "eureka-design"
import { useSelector } from "react-redux"
import { useObtenerMembresiaQuery } from "../../js/service/membresia"
import { useEffect, useState } from "react"
import noImage from "../../img/nologo.png"
import { numberFormat, obtenerFoto } from "../../js/util"

export const CARD_MEMBRESIA = 'card_membresia'

export default function Membresia() {
    /** ESTADOS GLOBALES */
    const sesionSlice = useSelector(state => state.sesionSlice)
    const cardMembresia = useSelector(state => state.handlerQuerySlice[CARD_MEMBRESIA])

    /** HOOK */
    const { data: membresia, isLoading: cargandoMembresia } = useObtenerMembresiaQuery({ 
        token: sesionSlice.token, 
        id: cardMembresia.id 
    })

    /** ESTADOS */
    const [subscripcion, fijaSuscripcion] = useState(null)
    const [beneficio, fijaBeneficio] = useState(null)

    useEffect(() => {
        if(!cargandoMembresia) {
            // console.log(membresia)
            fijaSuscripcion(membresia.eureka_pa_subscripciones[0] ?? null)
            fijaBeneficio(membresia.eureka_me_membresias_beneficios[0] ?? null)
        }
        // else esperando datos
    }, [cargandoMembresia, membresia, fijaSuscripcion, fijaBeneficio])
    
    if(cargandoMembresia) {
        return
        // return <p>Cargando membresia, por favor espere...</p>
    }
    else {
        return (
            <CardEvento titulo={membresia?.nombre} fecha={subscripcion?.recurrencia}
                imagen={obtenerFoto(membresia, noImage)}
                opciones={[
                    // { texto: "Ver", tooltip: "Ver mas", icono: "fa-eye", onClick: () => { } },
                    // { 
                    //     texto: "Editar", 
                    //     tooltip: "Editar", 
                    //     icono: "fa-edit",
                    //     onClick: () => {
                    //         Push('form_membresias', { membresia: membresia })
                    //     }
                    // },
                    // { texto: "Eliminar", tooltip: "Eliminar", icono: "fa-trash", onClick: () => { } },
                ]}
                extras={[
                    { icono: "fa-users", texto: `${subscripcion?.eureka_pa_subscriptores?.length ?? 0} usuarios` },
                    { icono: "fa-dollar", texto: `${subscripcion?.moneda ?? 'MXN'} ${numberFormat(subscripcion?.precio ?? 0)}` },
                    { icono: "fa-video", texto: `${beneficio?.incremento ?? 0} tema(s) permitido(s)` },
                ]}>

            </CardEvento>
        )
    }
}