import { Formulario, Input, ImageInput, Select } from "eureka-design"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useCrearUsuarioMutation, useGuardarFotoPerfilMutation, useObtenerUsuarioQuery, useRelacionarFotoPerfilMutation } from "../../js/service/usuario"
import { adaptarDatosSelect, obtenerFoto } from "../../js/util"
import { useCrearSubcriptorMutation, useObtenerMembresiasQuery } from "../../js/service/membresia"

export const FORM_USUARIO = 'form_usuario'

export default function FormUsuario({ ShowAlert, esEdicionPropia = false }) {
    const dispatch = useDispatch()

    /** OBTENER ESTADOS GLOBALES */
    const formUsuario = useSelector(state => state.handlerQuerySlice[FORM_USUARIO])
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** ESTADOS */
    const [esCreacion] = useState(formUsuario.id === null)
    const [inicializandoDatos, cambiarEstadoInicializacion] = useState(true)
    const [usuarioID, fijaUsuarioID] = useState(formUsuario.id)
    const [fotoID, fijaFotoID] = useState(null)
    const [subscriptorID, fijaSubscriptorID] = useState(null)

    /** HOOK */
    const { data: usuario, isLoading: cargandoUsuario, error: errorUsuario } = useObtenerUsuarioQuery({ 
        token: sesionSlice.token, 
        id: formUsuario.id
    })
    const {
        data: membresias,
        isLoading: cargandoMembresias,
        error: errorMembresias
    } = useObtenerMembresiasQuery({ token: sesionSlice.token })
    const [
        crearUsuario, 
        {
            isLoading: actualizandoUsuario,
            isError: usuarioErroneo,
            isSuccess: usuarioActualizado,
        }
    ] = useCrearUsuarioMutation()
    const [
        guardarFotoPerfil, 
        {
            isLoading: actualizandoFoto,
            isError: fotoErroneo,
            isSuccess: fotoActualizado,
        }
    ] = useGuardarFotoPerfilMutation()
    const [relacionarFotoPerfil] = useRelacionarFotoPerfilMutation()
    const [
        crearSubcriptor,
        {
            isLoading: actualizandoSubscriptor,
            isError: subscriptorErroneo,
            isSuccess: subscriptorActualizado,
        }
    ] = useCrearSubcriptorMutation()

    useEffect(() => {
        if(!cargandoUsuario) {
            if(inicializandoDatos) {
                // console.log(usuario)
                fijaFotoID(usuario?.eureka_cr_multimedia[0]?.id)
                fijaSubscriptorID(usuario?.eureka_pa_subscriptores[0]?.id)
                cambiarEstadoInicializacion(false)
            }
            // else se inicializaron las variables
        }
        // else cargando información de usuario
    }, [fijaFotoID, fijaSubscriptorID, inicializandoDatos, cambiarEstadoInicializacion, cargandoUsuario, usuario])

    /** MANEJADORES */
    async function handlerUsuario(nom, val, id = null) {
        return await crearUsuario({
            id: id ? id : usuarioID,
            token: sesionSlice.token,
            body: { [nom]: val }
        })
        .unwrap()
        .then((response) => {
            if(response?.mensaje?.id) {
                formUsuario('id', response.mensaje.id)
                fijaUsuarioID(response.mensaje.id)
                return response.mensaje.id
            }
            else return usuarioID
        })
    }

    async function handlerFoto(e) {
        let usuarioId = null
        if(usuarioID) {
            usuarioId = usuarioID
        }
        else {
            usuarioId = await crearUsuario('nombre', '')
        }

        guardarFotoPerfil({
            id: fotoID,
            token: sesionSlice.token,
            configFile: {
                file: e.target.files[0],
                carpeta: 'imagenes',
                nombre: 'nombre',
            }
        })
        .unwrap()
        .then((response) => {
            if(response?.mensaje?.id) {
                relacionarFotoPerfil({
                    id: response.mensaje.id,
                    token: sesionSlice.token,
                    body: { 'usuario': usuarioId }
                })
                fijaFotoID(response.mensaje.id)
            }
            // else se actualizo la imagen
        })
    }

    const handlerInput = async (val, nom) => {
        if(!usuario?.fecha_registro) {
            let id = await handlerUsuario(nom, val)
            let fechaAlta = (new Date()).toISOString().split('T')
            
            handlerFormData('fecha_registro', fechaAlta[0])
            handlerUsuario('fecha_registro', fechaAlta[0], id)
        }
        else {
            handlerUsuario(nom, val)
        }
        handlerFormData(nom, val)
    }

    const handlerMembresia = async (val, nom) => {
        let membresiaElegida = membresias.filter(membresia => membresia.id === val.value)

        if(membresiaElegida.length > 0) {
            let subscripcion = membresiaElegida[0]?.eureka_pa_subscripciones[0]?.id

            let usuarioId = null
            if(usuarioID) {
                usuarioId = usuarioID
            }
            else {
                usuarioId = await crearUsuario('nombre', '')
            }

            crearSubcriptor({
                id: subscriptorID,
                token: sesionSlice.token,
                body: {
                    'usuario': usuarioId,
                    'subscripcion': subscripcion
                }
            })
            .unwrap()
            .then((response) => {
                if(response?.mensaje?.id) {
                    fijaSubscriptorID(response.mensaje.id)
                }
                // else se actualizo la imagen
            })
        }
        else {
            ShowAlert({
                icono: 'error',
                titulo: 'Membresia no válida',
                mensaje: 'Intentelo de nuevo, la membresía no se reconocio'
            })
        }
    }

    const handlerFormData = (key, val) => {
        dispatch(
            setHandler({
                state: FORM_USUARIO,
                content: {
                    id: usuarioID,
                    usuario: {
                        ...usuario,
                        [key]: val
                    }
                }
            })
        )
    }

    if(errorUsuario || errorMembresias) {
        return <p>{errorUsuario ?? errorMembresias}</p>
    }
    else if(cargandoUsuario || cargandoMembresias || inicializandoDatos) {
        return
        // return <p>Cargando información del usuario, por favor espere...</p>
    }
    else {
        let selectMembresia
        if(!esEdicionPropia) {
            selectMembresia = (
                <Select
                    nombre="membresia"
                    iconLeft="fa-solid fa-users"
                    placeholder="Membresía a la que pertenece"
                    options={adaptarDatosSelect(membresias, 'id', 'nombre')}
                    changeFunction={handlerMembresia}
                    isLoading={actualizandoSubscriptor}
                    value={usuario?.eureka_pa_subscriptores[0]?.eureka_pa_subscripcione?.nombre}
                ></Select>
            )
        }
        else {
            selectMembresia = null
        }

        return (
            <Formulario
                titulo={`${esCreacion ? 'Crear' : 'Editar'} Usuario`}
                isLoading={[actualizandoUsuario, actualizandoFoto, actualizandoSubscriptor]}
                isError={[usuarioErroneo, fotoErroneo, subscriptorErroneo]}
                isSuccess={[usuarioActualizado,fotoActualizado, subscriptorActualizado]}
            >
                <ImageInput
                    id="portada"
                    preview={obtenerFoto(usuario)}
                    avatar={true}
                    changeFunction={handlerFoto}
                    isLoading={actualizandoFoto}
                />
                <Input
                    type="text"
                    nombre="nombre"
                    value={usuario?.nombre}
                    placeholder="Nombre"
                    changeFunction={handlerInput}
                    isLoading={actualizandoUsuario}
                    iconLeft="fa-solid fa-user"
                />
                <Input
                    type="text"
                    nombre="correo"
                    value={usuario?.correo}
                    placeholder="Correo"
                    changeFunction={handlerInput}
                    isLoading={actualizandoUsuario}
                    iconLeft="fa-solid fa-envelope"
                />
                <Input
                    type="telefono"
                    nombre="telefono"
                    value={usuario?.telefono}
                    placeholder="Teléfono"
                    changeFunction={handlerInput}
                    isLoading={actualizandoUsuario}
                    iconLeft="fa-solid fa-phone"
                />
                {selectMembresia}
            </Formulario>
        )
    }
}