import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'
const TABLA_USUARIO = "EurekaAcUsuario"

export const usuarioApi = createApi({
    reducerPath: 'usuarioApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerUsuarios: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO)
                                .where('estatus', '=', 'activo')
                                .with('eureka_cr_rol_usuarios.eureka_cr_rol')
                                .with('eureka_cr_multimedia')
                                .with('eureka_pa_subscriptores.eureka_pa_subscripcione')
                                .get()
                }
            }
        }),
        obtenerUsuario: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO)
                if(data.id) {
                    body = body.where('id', '=', data.id)
                }
                else {
                    body = body.where(data.columna, '=', data.valor)
                }
                body = body
                        .with('eureka_cr_rol_usuarios.eureka_cr_rol')
                        .with('eureka_cr_multimedia')
                        .with('eureka_pa_subscriptores.eureka_pa_subscripcione')
                        .first()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearUsuario: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO)
                if(data.id) {
                    body = body.where('id', '=', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        guardarFotoPerfil: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) {
                    body = body.where('id', data.id)
                }
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${data.token}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        relacionarFotoPerfil: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA).where('id', '=', data.id).update(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})

export const {
    useObtenerUsuariosQuery,
    useObtenerUsuarioQuery,
    useCrearUsuarioMutation,
    useGuardarFotoPerfilMutation,
    useRelacionarFotoPerfilMutation,
} = usuarioApi